import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import Logo from "../images/kdk-logo-text.inline.svg"
import Img from "gatsby-image"

class AboutPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO
          title="About Us | Kingdom Kids"
          description={`Our story started with three people who felt the burden of the Father’s heart for the extensive number of broken children in Logan.`}
        />
        <Nav />
        <div className="kdk-nav-bg kdk-top-section"></div>
        <section className="kdk-about">
          <div className="container">
            <div className="kdk-about-content__image-container">
              <div className="kdk-about-content__image">
                <Img fluid={data.teamShot.childImageSharp.fluid} />
              </div>
            </div>
            <div className="kdk-about-content__text">
              <h1>raising up a generation of Jesus-loving kids</h1>
              <p>
                Our story started with three people who felt the burden of the
                Father’s heart for the extensive number of broken children in
                Logan.
              </p>

              <p>
                These kids experience hurt and neglect as a result of widespread
                domestic violence, drug and alcohol abuse and fatherlessness.
              </p>

              <p>
                The effects can be seen across Logan: dirty, hungry and lonely
                children left outside without the care and love they need.
              </p>

              <p>
                The cycle of addiction is clear: children grow up and fall into
                the same traps their parents did.
              </p>

              <p>
                Love for these children inspired the idea to run a program in a
                local park. The purpose was to share God’s loving heart with
                them. We believed that by simply giving these children
                unconditional love, their lives could be changed forever - and
                that is what we have seen.
              </p>

              <p>
                The Kingdom Kids park events are run very simply. We gather with
                the children, run games, share a short message from God’s word
                and provide afternoon tea. Through this we build relationships
                with the children, and and it’s through those relationships that
                their lives are transformed.
              </p>

              <p>
                We believe that the park events are simply the launchpoint for
                greater investment into the lives of the children’s families. By
                building relationships with the parents as well, we are seeing
                whole households experience transformation.
              </p>

              <p>
                Our hope is that this model can be replicated to impact more
                children and that many can be awakened to and transformed by the
                Father’s heart.
              </p>
              <div className="with-love">With</div>
              <div className="logo">
                <Logo />
              </div>
            </div>
          </div>
        </section>
        <section className="kdk-about-gallery">
          <div className="kdk-about-gallery__item">
            <Img fluid={data.gallery1.childImageSharp.fluid} />
          </div>
          <div className="kdk-about-gallery__item">
            <Img fluid={data.gallery2.childImageSharp.fluid} />
          </div>
          <div className="kdk-about-gallery__item">
            <Img fluid={data.gallery3.childImageSharp.fluid} />
          </div>
          <div className="kdk-about-gallery__item">
            <Img fluid={data.gallery4.childImageSharp.fluid} />
          </div>
        </section>
        <section className="kdk-about-join-us">
          <div className="container">
            <h2>Want to join us?</h2>
            <p>
              Please reach out to us if God puts these kids on your heart and
              you would like to support by praying for us, donating,
              volunteering or joining the team.
            </p>
            <a href="mailto:info@kingdomkids.org.au" className="btn-default">
              Contact Us
            </a>
          </div>
        </section>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    teamShot: file(relativePath: { eq: "team-shot-1.png" }) {
      childImageSharp {
        fluid(traceSVG: { color: "#fff100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    gallery1: file(relativePath: { eq: "gallery-1.png" }) {
      childImageSharp {
        fluid(traceSVG: { color: "#fff100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    gallery2: file(relativePath: { eq: "gallery-2.png" }) {
      childImageSharp {
        fluid(traceSVG: { color: "#000000" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    gallery3: file(relativePath: { eq: "gallery-3.png" }) {
      childImageSharp {
        fluid(traceSVG: { color: "#F92CCB" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    gallery4: file(relativePath: { eq: "gallery-4.png" }) {
      childImageSharp {
        fluid(traceSVG: { color: "#fff100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
